<template>
  <div>
    <PageContainer
    :title="$translations.account.delete.title"
    :back="actions.back"
    :show-left-button="true"
    :has-shy-header="true"
    :ellipsis="true"
    >
      <template v-slot:content>

        <div class="page-segment">
          <div class="page-segment-content">
            <div class="heading-small padding-bottom">{{ $translations.account.delete.sections[0].title }}</div>
            <div class="body padding-bottom">{{ $translations.account.delete.sections[0].description }}</div>
            <Button @clicked="onOpenKustomer" :text="$translations.account.delete.sections[0].button"/>
          </div>
        </div>

        <div class="page-segment">
          <div class="page-segment-content">
            <div class="heading-small padding-bottom">{{ $translations.account.delete.sections[1].title }}</div>
            <div class="body padding-bottom">{{ $translations.account.delete.sections[1].description }}</div>
            <Button @clicked="navigateNotificationSettings" :text="$translations.account.delete.sections[1].button"/>
          </div>
        </div>

        <div class="page-segment">
          <div class="page-segment-content">
            <div class="heading-small padding-bottom">{{ $translations.account.delete.sections[2].title }}</div>
            <div class="body padding-bottom">{{ $translations.account.delete.sections[2].description[0] }}</div>
            <div class="body padding-bottom text-red">{{ $translations.account.delete.sections[2].description[1] }}</div>
            <div class="body padding-bottom-lg">{{ $translations.account.delete.sections[2].description[2] }}</div>

            <div class="padding-bottom">
              <label for="email">{{ $translations.account.delete.form['email-label'] }}</label>
              <InputEmailField
                class="margin-top-xs"
                id="email"
                type="email"
                v-model="form.email"
                @change="setForm('email', $event)"
                :label="$translations.account.delete.form['email-field']"
              />

              <div class="form-field-error" v-if="formError.email">
                {{ formError.email }}
              </div>
            </div>

            <div class="padding-bottom">
              <label class="margin-bottom-zero" for="reason">{{ $translations.account.delete.form['reason-label'] }}</label>
              <TextareaField
                id="reason"
                class="margin-top-xs"
                :label="$translations.account.delete.form['reason-field']"
                :maxLength="250"
                :value="form.reason"
                @change="setForm('reason', $event)"
              />
            </div>
            <Button
              @clicked="showDialog = true"
              :disable="!formIsValid"
              :icon="'icon-warning-triangle-on'"
              :icon-left="true"
              :color="'red'"
              :text="$translations.account.delete.sections[2].button"/>
          </div>
        </div>
      </template>
    </PageContainer>

    <!-- modals -->
    <DeleteAccountDialog v-if="showDialog" :data="form" @closeDialog="closeDialog"/>
  </div>
</template>

<script>
import { PageContainer, Button } from '@seliaco/red-panda'
import { InputEmailField, TextareaField } from '@seliaco/sea-otter'
import { mapGetters } from 'vuex'
import DeleteAccountDialog from '@/components/dialogs/DeleteAccountDialog'

export default {
  name: 'DeleteAccount',
  components: {
    PageContainer,
    Button,
    TextareaField,
    DeleteAccountDialog,
    InputEmailField
  },
  data () {
    return {
      form: {
        email: null,
        reason: null
      },
      formError: {
        email: null
      },
      actions: {
        back: {
          callback: () => {
            this.$router.go(-1)
          }
        }
      },
      showDialog: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    formIsValid () {
      return Boolean((!this.formError.email && this.form.email) && this.form.reason)
    }
  },
  methods: {
    validateEmail (email) {
      this.formError.email = this.user.email !== email
        ? this.$translations.account.delete.form.error
        : null
    },
    setForm (type, value) {
      if (type === 'email') {
        this.validateEmail(value)
      }
      this.form[type] = value
    },
    closeDialog () {
      this.showDialog = false
    },
    onOpenKustomer () {
      this.$kustomer.openChat(this.$store.getters.platform)
    },
    navigateNotificationSettings () {
      this.$router.push({
        name: 'NotificationSettings'
      })
    }
  }
}
</script>

<template>
  <DialogContainer
    :icon="'icon-trash-on'"
    :title="$translations.account.delete.modal.title"
    :custom-icon="true"
    :icon-color="'red'"
    @closeDialog="closeDialog()"
  >
    <template v-slot:content>
      <div class="body padding-bottom">
        {{ description }}
      </div>

      <div class="delete-account-buttons">
        <Button @clicked="navigateHome" :text="$translations.account.delete.modal.buttons[0]" />
        <Button
          @clicked="deleteUser"
          :icon="'icon-warning-triangle-on'"
          :icon-left="true"
          :color="'red'"
          :text="$translations.account.delete.modal.buttons[1]" />
        <Button @clicked="closeDialog" :type="'outline'" :text="$translations.account.delete.modal.buttons[2]" />
      </div>
    </template>
  </DialogContainer>
</template>

<script>
import { Button, DialogContainer, Users } from '@seliaco/red-panda'

export default {
  name: 'DeleteAccountDialog',
  components: { DialogContainer, Button },
  props: {
    data: {
      email: String,
      reason: String
    }
  },
  computed: {
    description () {
      return this.$translations.account.delete.modal.description.replaceAll('{email}', this.data.email)
    }
  },
  methods: {
    deleteUser () {
      Users.deleteUser(encodeURIComponent(this.data.email), this.data.reason)
        .then(() => {
          this.handleSignOut()
          this.$toast({
            text: this.$translations.account.delete.toast,
            severity: 'success'
          })
        })
        .catch((e) => {
          this.$toast({
            text: e.message || this.$translations.error.default,
            severity: 'error'
          })
        })
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    navigateHome () {
      this.$router.push({
        name: 'Home'
      })
    },
    handleSignOut () {
      localStorage.clear()
      this.$auth.signOut()
      this.$router.push({
        name: 'SignIn',
        query: this.$route.query || null
      })
      this.$kustomer.signOut(this.$store.getters.platform)
    }
  }
}
</script>

<style lang="sass" scoped>
.delete-account-buttons
  display: grid
  gap: 8px
::v-deep .icon-display
  font-size: 34px
</style>
